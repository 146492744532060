import logo from './img/pixsol.png';
import tokennom from './img/tokennomics.png';
import steps from './img/steps.png';
import './home.css'

function Home() {
  return (
    <div className='home'>
      <div className='left'>
        <img className='img-left' src={logo} alt="Logo" />
      </div>

      <div className='right'>
        <h1 className='h1-right'>pixSOL <br /> Made with &#10084; in germany!</h1>
        <h4 className='h4-right'>With a bit of luck, win up to 100 sol!</h4>
      </div>

      
      <div className='left'>
        <h1 className='h1-left'>tokennomics <br /> Enough tokens for everyone!</h1>
        <h4 className='h4-left'>100.000.000 pixSOL created</h4>
        <h4 className='h4-left'>LP burned</h4>
        <h4 className='h4-left'>mint revoked</h4>
      </div>

      <div className='right'>
        <img className='img-right' src={tokennom} alt="Tokennomics" />
      </div>

      
      <div className='left'>
        <img className='img-left-1' src={steps} alt="Steps" />
      </div>

      <div className='right'>
        <h1 className='h1-right'>step 1</h1>
        <h4 className='h4-right'>create pixSOL token</h4>
        <h1 className='h1-right'>step 2</h1>
        <h4 className='h4-right'>listening on raydium</h4>
      </div>
    </div>
  );
}

export default Home;
import { Routes, Route, Outlet, Link, HashRouter } from "react-router-dom";
import './LinkMenu.css';
import Home from './Home';
import About from './About';
import Games from './Games';

function App() {
  return (
      <div>
        <HashRouter>
          <Routes>
            <Route element={<Layout />} >
              <Route index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="games" element={<Games />} />
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </HashRouter>
      </div>
  );
}

function Layout() {
  return (
    <div>
      <nav>
        <ul className="link-list">
          <li>
            <Link to="/">pixSOL</Link>
          </li>
          <li>
            <Link to="/about">about</Link>
          </li>
          <li>
            <Link to="/games">let's play</Link>
          </li>
        </ul>
      </nav>
      <Outlet />
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
export default App;